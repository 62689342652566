$primaryfont: "Roboto", sans-serif;
$secundaryfont: 'Poppins', sans-serif;
$secondaryfont: "Playfair Display", serif;
$roboto: "Roboto", sans-serif;

$t-small: 1px;
$extrasmall: 2px;
$medsmall: 4px;
$dssmall: 5px;
$extra-tiny: 8px;
$tiny: 10px;
$small: 12px;
$smallplus: 13px;
$regular: 14px;
$regularplus: 15px;
$medium: 16px;
$large: 18px;
$largeplus: 20px;
$largeplusplus: 22px;
$extralarge: 24px;
$extralargeplus: 25px;
$homecardhuge: 26px;
$huge: 28px;
$morehuge: 30px;
$hometitletablet: 32px;
$hugeplus: 34px;
$hugeplustitle: 35px;
$extrahuge: 36px;
$mediumtitle: 37px;
$mediumtitleplus: 38px;
$extrahugetitle: 40px;
$mediumhugetitle: 42px;
$extratext: 44px;
$extratextmore: 46px;
$extrahugeplus: 48px;
$extrahugeplustitle: 50px;
$hometitlesize: 56px;
$titlemedium: 61px;
$extraextrahuge: 70px;
$moreextra: 80px;
$m-large: 90px;
$giant: 100px;
$v-giant: 125px;
$gianttile: 142px;
$extragiant: 150px;

$tinyPad: 5px;
$smallPad: 10px;
$regularPad: 20px;
$mediumPad: 30px;
$bigPad: 40px;
$extrabigpad: 50px;
$largePad: 60px;
$hugePad: 80px;

$titleauth: rgba(5, 6, 10, 1);
$refund-pending-title: rgba(139, 75, 0, 1);
$refund-pending-bg: rgba(139, 75, 0, 0.2);
$refunded-title: rgba(38, 132, 221, 1);
$refunded-bg: rgba(38, 132, 221, 0.2);
$pending-title: rgba(227, 171, 8, 1);
$pending-order: rgba(227, 171, 8, 0.2);
$select-gray: rgba(238, 238, 238, 1);
$purplehover: rgba(49, 15, 162, 0.4);
$darkpurple: rgba(49, 15, 162, 1);
$menu-purple: rgba(26, 18, 60, 1);
$sign-up-blue: rgba(84, 59, 239, 1);
$sign-up-blue-light: rgba(84, 59, 239, 0.2);
$sign-up-blue-hover: rgba(84, 59, 239, 0.10);
$menu-purple-hover: rgba(45, 38, 76, 1);
$midgray: rgba(112, 112, 112, 1);
$progressgray: rgba(214, 214, 214, 1);
$tablegray: rgba(217, 217, 217, 1);
$darkergray-medium: rgba(86, 89, 116, 0.5);
$ds-home: rgba(59, 60, 81, 1);
$grayborder: rgba(59, 60, 81, 0.1);
$ds-faq: rgb(239, 240, 254);
$ds-home-button: rgba(88, 101, 242, 1);
$ds-header: rgba(59, 60, 81, 1);
$ds-content: rgba(35, 39, 42, 0.75);
$ds-purple: rgba(67, 0, 169, 1);
$menu-white: rgba(222, 224, 252, .1);
$ds-blue: rgba(0, 51, 140, 1);
$ds-gray: rgba(229, 229, 229, 1);
$ds-green: rgba(0, 180, 105, 1);
$ds-mediumgreen: rgba(0, 182, 122, 1);
$ds-footer-link : rgb(88, 101, 242, 1);
$ds-faq-purple: rgba(77, 56, 211, 1);
$dsgray-text: rgba(35, 39, 42, 0.9);
$yellow: rgba(253, 215, 102, 1);
$dark-yellow: rgba(227, 171, 8, 1);
$mustard: rgba(226, 160, 21, 0.3);
$off-yellow: rgba(254, 215, 102, 0.2);
$tag-yellow: rgba(211, 144, 3, 1);
$faded-yellow: rgba(212, 215, 80, 0.35);
$slategrey: rgba(84, 101, 133, 1);
$slateblue: rgba(132, 155, 198, 1);
$blue: rgba(2, 50, 141, 1);
$light-blue: rgba(0, 143, 174, 1);
$off-light-blue: rgba(0, 143, 174, 0.3);
$dark-blue: rgba(0, 46, 128, 1);
$green: rgba(57, 226, 157, 1);
$notification-green: rgba(57, 226, 157, 1);
$success-green: rgba(0, 174, 103, 1);
$second-success-green: rgba(0, 180, 105, 1);
$tag-green: rgba(90, 213, 87, 0.3);
$off-green: rgba(0, 174, 103, 0.1);
$red: rgba(253, 74, 74, 1);
$notification-red: rgba(251, 74, 74, 1);
$off-red: rgba(255, 0, 0, 0.2);
$brown: rgba(162, 39, 0, 1);
$lighterbrown: rgba(137, 98, 15, 1);
$off-brown: rgba(162, 39, 0, 0.35);
$white: rgba(255, 255, 255, 1);
$shadow: rgba(44, 45, 51, 0.8);
$lightergray: rgba(250, 250, 255, 1);
$lightgray: rgba(255, 255, 255, 0.75);
$inputgray: rgba(249, 249, 249, 1);
$regulargray: rgba(255, 255, 255, 0.6);
$divisor-gray: rgba(196, 196, 196, 0.6);
$divisor-gray-light: rgba(196, 196, 196, 0.3);
$medium-gray: rgba(128, 128, 128, 1);
$palegray: rgba(243, 245, 247, 1);
$whitesmoke: rgba(244, 244, 244);
$mediumgray: rgba(247, 247, 247, 1);
$gray: rgba(255, 255, 255, 0.5);
$yellow-hover: rgba(254, 215, 102, 0.5);
$success-green-hover: rgba(0, 174, 103, 0.5);
$red-hover: rgba(253, 74, 74, 0.5);
$input-placeholder-hover: rgba(0, 0, 0, 0.6);
$darkgray: rgba(255, 255, 255, 0.3);
$jetgray: rgba(43, 44, 59, 1);
$darkergray: rgba(86, 89, 116, 1);
$transparent: rgba(0, 0, 0, 0);
$softblack: rgba(70, 70, 70, 1);
$mediumblack: rgba(51, 51, 51, 1);
$black: rgba(0, 0, 0, 1);
$purple-text: rgba(49, 15, 162, 1);
$off-purple-text: rgba(46, 0, 144, 0.3);
$dark-purple: rgba(44, 0, 111, 1);
$lilac: rgba(87, 97, 213);
$lilac-alternative: rgba(87, 98, 213, 1);
$lighterlilac: rgba(167, 135, 215, 1);
$offlilac: rgba(87, 98, 213, 0.2);
$input-placeholder: rgba(0, 0, 0, 0.4);
$dropdownprimarybackground: rgba(26, 18, 60, 1);
$dropdownsecondarybackground: rgb(60, 61, 77);
$text-btncontact: rgb(35, 39, 42);
$gray-text: rgb(59, 60, 81);
$dstext-gray: rgb(35, 39, 42);
$pending-yellow-font: rgba(253, 215, 102, 1);
$pending-yellow-background: rgba(254, 215, 102, 0.1);
$dropdownhighlight: $yellow;
$dropdownunhighlight: rgb(150, 151, 156);
$backgroundcontrol: rgba(249, 249, 249, 1);
$ds-purple-gradient: linear-gradient(to right,
        $ds-purple 2.45%,
        $ds-blue 101.9%);
$ds-progress-gradient: linear-gradient(to right,
        $ds-purple 50%,
        rgba(197, 204, 229, 1) 50%);
$primarybackground: rgba(55, 56, 75, 1);
$offblack: rgba(55, 56, 75, 1);
$secondarybackground: rgba(48, 49, 65, 1);
$tertiarybackground: rgba(60, 61, 77, 1);
$hoverbackground: rgba(59, 67, 92, 1);
$primarycolor: rgba($white, 1);
$dropdownwhite: rgba($white, 0.05);
$primaryborder: rgba(76, 96, 133, 1);
$secondarycolor: rgba(79, 171, 171, 1);
$purple-disabled: rgba(49, 15, 162, 0.2);
$dropdownmenu: rgba(249, 249, 249, 1);
$lightpurple: rgba(87, 98, 213, 1);
$lightpurplehover: rgba(87, 98, 213, 0.4);
$settings-title: rgba(59, 60, 81, 1);
$footer-link-gray: rgba(59, 60, 81, 0.8);
$status-closed: rgba(59, 60, 81, 0.2);
$slateblue-off-low-opacity: rgba(76, 96, 133, .2);
$ds-divider: rgba(213, 213, 213, 1);
$gray-description: rgba(64, 62, 66, 1);
$lilac-hover: rgba(241, 238, 250, 1);
$lilac-hover-medium: rgba(241, 238, 250, 0.5);
$darkerblue: rgba(26, 18, 60, 1);
$color-grey-1000: rgba(31, 31, 31, 1);
$color-grey-700: rgba(107, 107, 107, 1);
$color-grey-300: rgba(231, 231, 231, 1);
$color-grey-200: rgba(247, 247, 247, 1);
$color-grey-100: rgba(252, 252, 252, 1);
$primary:rgba(73, 49, 250, 1);
$primary-300: rgba(221, 221, 255, 1);
$primary-200: rgba(239, 239, 253, 1);
$primary-100: rgba(236, 240, 255, 1);
$cetacean-blue: rgba(0, 0, 74, 1);
$purple-blue: rgba(87, 80, 255, 1);

$snackbar-info: rgba(82, 115, 202, 1);
$snackbar-error: rgba(84, 49, 61, 1);
$snackbar-warning: rgba(84, 77, 66, 1);
$snackbar-success: rgba(44, 79, 77, 1);
$regular-border: 1px solid rgba(58, 33, 214, 1);

$smallphone: 320px;
$phone: 576px;
$tablet: 768px;
$authphone: 675px;
$desktop: 992px;
$mediumcreen: 1400px;
$widescreen: 1200px;
$extrascreen: 1400px;
$megascreen: 1550px;
$extralargescreen: 1920px;
$ultrahdscreen: 3840px;

@mixin background-properties() {
    background: transparent linear-gradient(90deg,
            rgba(17, 22, 52, 1) 0%,
            rgba(18, 28, 74, 1) 49%,
            rgba(17, 22, 52, 1) 100%) 0 0 no-repeat padding-box;
    /* w3c */
}

@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
