@import 'styles/variables';

#sell {
    padding-bottom: 120px;

    @media (max-width: $desktop) {
        background-color: $white;
        padding-bottom: $largePad;
    }

    .banner-hide {
        @media (max-width: $desktop) {
            display: none;
        }
    }

    #ds-template-paragraph-image .first {
        justify-self: start;
    }

    #ds-template-paragraph {
        margin: $hugePad 0;

        @media (max-width: $desktop) {
            margin: $largePad 0;
        }

        @media (max-width: $phone) {
            margin: $bigPad 0;
        }

        .title p {
            font-family: $primaryfont;
            font-size: $extralarge;
            line-height: 28px;
        }
        
        .displayFairFont p {
            font-family: $primaryfont;
            font-size: $regular;
            margin: 0;
            opacity: 0.75;
        }
    }

    .spin-loader {
        margin-top: 150px;
    }

    .disabled {
        pointer-events: none;
        opacity: 0.5;
    }

    .no-results {
        font-weight: 400;
        font-size: $regular;
        margin: 45px auto $bigPad;
        background: $white;
        padding: $bigPad 17px;
        width: 100%;

        @media ($tablet <= width) {
            margin: 25px auto $bigPad;
        }

        @media ($desktop <= width) {
            margin: $regularPad auto $largePad;
        }

        p {
            color: $ds-header;
            max-width: 250px;
            margin: $regularPad auto 0;

            @media ($tablet <= width) {
                max-width: 404px;
            }
        }
        
        img {
            height: 125px;
        }
    }

    .banner-hide {
        @media (max-width: $desktop) {
            display: none;
        }
    }

    #ds-template-paragraph-image .first {
        justify-self: start;
    }

    #ds-template-paragraph {
        margin: $hugePad 0;

        @media (max-width: $desktop) {
            margin: $largePad 0;
        }

        @media (max-width: $phone) {
            margin: $bigPad 0;
        }
    }

    #ds-template-paragraph .title p {
        font-family: $primaryfont;
        font-size: $extralarge;
        line-height: 28px;
    }

    #ds-template-paragraph .displayFairFont p {
        font-family: $primaryfont;
        font-size: $regular;
        margin: 0;
        opacity: 0.75;
    }

    .disabled {
        pointer-events: none;
        opacity: 0.5;
    }

    .no-results {
        font-weight: 400;
        font-size: $regular;
        margin: 45px auto $bigPad;
        background: $white;
        padding: $bigPad 17px;
        width: 100%;

        @media ($tablet <= width) {
            margin: 25px auto $bigPad;
        }

        @media ($desktop <= width) {
            margin: $regularPad auto $largePad;
        }

        p {
            color: $ds-header;
            max-width: 250px;
            margin: $regularPad auto 0;

            @media ($tablet <= width) {
                max-width: 404px;
            }
        }
        
        img {
            height: 125px;
        }
    }

    .button-container {
        gap: 20px;

        @media (max-width: $desktop) {
            gap: 15px;
        }

        @media (max-width: $phone) {
            gap: 5px;
        }
    }

    .select-game {
        color: $darkerblue;
        font-size: $large;

        @media (max-width: $desktop) {
            font-size: $medium;
            margin-top: 4px;
        }
    }

    .select-game-container {
        padding: 16px 0;

        @media (max-width: $desktop) {
            padding: 0 0 16px;
        }
    }

    .main-title {
        font-size: $extrahugetitle;
        color: $darkerblue;
        margin-bottom: $bigPad;

        @media (max-width: $desktop) {
            font-size: $extralarge;
            margin-bottom: 0;
        }
    }

    #ds-page-content-area {
        margin: 0;
        font-size: $extrahugetitle;
        color: $darkerblue;
        font-weight: 700;

        @media (max-width: $desktop) {
            font-size: $extralarge;
        }
    }

    .currency-container {
        margin-top: 64px;

        @media (max-width: $desktop) {
            margin-top: 24px;
        }

        @media (max-width: $phone) {
            margin-top: 4px;
        }
    }

    .quantity-field {
        max-width: 430px;
        width: 100%;

        @media (max-width: $desktop) {
            max-width: 100%;
        }
    }

    .payment-methods-container {
        @media (max-width: $desktop) {
            width: 100%;
            margin-top: 30px;
            margin-right: 20px;
        }

        @media (max-width: $phone) {
            width: 100%;
            margin-top: 5px;
            margin-right: 0px;
        }
    }

    #ds-payment-method-selector {
        .payment-method-scrollbar {
            max-height: 275px;
        }

        .payment-method-component {
            max-width: 430px !important;
            width: 100%;

            @media (max-width: $desktop) {
                max-width: 100% !important;
            }
        }
    }

    .amount {
        color: $darkergray;
    }

    .live-chat-button {
        background-color: $palegray;
        color: $darkergray;
        max-width: 294px;
        max-height: 50px;
        border-style: none;
        border-radius: 4px;
        font-size: $medium;
        padding: 10px;

        @media (max-width: $desktop) {
            max-width: 208px;
            max-height: 60px;
        }

        @media (max-width: $phone) {
            font-size: $regular;
            max-width: 160px;
            max-height: 60px;
        }
    }

    .quote-button {
        background-color: $sign-up-blue;
        border-style: none;
        border-radius: 4px;
        color: $white;
        font-size: $medium;
        max-width: 294px;
        max-height: 50px;

        @media (max-width: $desktop) {
            max-width: 208px;
            max-height: 60px;
        }

        @media (max-width: $phone) {
            font-size: $regular;
            max-width: 160px;
        }
    }

    .back-button-responsive{
        margin-bottom: 20px;
        color: $sign-up-blue;
    }

    .back-button-margin {
        margin-left: 26px;
        margin-top: 12px;
    }

    .currency-text-area {
        font-size: $extralarge;
        color: $darkerblue;
        margin-top: 25px;

        @media (max-width: $desktop) {
            font-size: $large;
        }

        @media (max-width: $phone) {
            font-size: $medium;
        }
    }

    .return {
        color: $sign-up-blue;
        cursor: pointer;
    
        .back-button {
            color: $sign-up-blue;

            @media (max-width: $desktop) {
                color: $sign-up-blue;
            }
        }

        @media (max-width: $desktop) {
            color: $sign-up-blue;
        }
    }

    .mdc-tab {
        background-color: $slateblue-off-low-opacity;
        height: 170px;
        width: 350px;
        border: unset;
    }

    .mdc-tab:hover {
        background-color: $slateblue-off-low-opacity;
    }

    .mdc-tab + .mdc-tab {
        margin-left: 20px;
    }

    .game-icon-area {
        align-items: center;
        display: flex;
        height: 100px;
        justify-content: center;
        border: 1px solid $transparent;
        transition: all .2s ease-in-out;
        width: 137px !important;
        background: $palegray;
        transition: all .2s ease-in-out;

        &:hover {
            background: $sign-up-blue-hover;
        }

        @media (max-width: $phone) {
            .img-fluid {
                max-height: 70px;
            }
        }
    }

    .game-box-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: normal;
        gap: 5px;
    }

    .game-name-area {
        color: $darkerblue;
        max-width: 140px;
        margin-bottom: 0;
        font-size: $small;
        margin-bottom: 0;
        font-size: $small;

        @media (max-width: $phone) {
            white-space: unset;
        }
    }

    .game-area {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 20px;
        margin: 0 226px !important;

        @media (max-width: $desktop) {
            grid-template-columns: repeat(4, 1fr);
            margin: $smallPad 0 0 !important;
            column-gap: $largePad;
        }

        @media (max-width: $phone) {
            grid-template-columns: repeat(2, 1fr);
            row-gap: 20px;
        }
    }

    .game-area-container {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: normal;
        flex-direction: row;

        @media (width <= $desktop) {
            margin-top: 0;
        }
    }

    .game-scrollbar {
        max-height: 710px !important;
        min-height: 710px !important;

        @media (max-width: $desktop) {
            max-height: 700px;
        }
    }

    .game-scrollbar {
        .simplebar-horizontal {
            display: none;
        }
    }

    .mdc-text-field {
        & .mdc-line-ripple {
            display: none;
        }
    }

    .game-search-element {
        margin-top: 4px;
        width: 100%;
        max-width: 765px;

        @media (max-width: $widescreen) {
            max-width: 604px;
        }

        @media (max-width: $desktop) {
            max-width: 604px;
            max-width: unset;
        }

        @media (max-width: $phone) {
            max-width: 271px;
            max-width: unset;

            input {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-right: 40px;
            }
        }
    }

    .search-bar {
        background-color: $palegray;
    }

    .white-circle-icon {
        right: 255px !important;

        @media (max-width: $widescreen) {
            right: 110px !important;
        }

        @media (max-width: $desktop) {
            right: 60px !important;
        }
       
        @media (max-width: $tablet) {
            right: 50px !important;
        }

        @media (max-width: $phone) {
            right: 32px !important;
        }
    }

    .category-text-area {
        font-size: $medium;

        @media (max-width: $desktop) {
            font-size: $regular;
        }

        @media (max-width: $phone) {
            font-size: $small;
        }
    }

    .category-icon {
        max-height: 60px;
        max-width: 60px;

        @media (max-width: $desktop) {
            max-height: 40px;
            max-width: 40px;
        }

        @media (max-width: $phone) {
            max-height: 30px;
            max-width: 30px;
        }
    }

    .category-area {
        color: $darkerblue;
        font-size: $medium;
        display: none;

        @media (max-width: $desktop) {
            font-size: $regular;
        }

        @media (max-width: $phone) {
            font-size: $tiny;
        }
    }

    .category-content-block {
        background: $white;
        border-radius: 5px;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.10);

        @media (max-width: $desktop) {
            height: 168px;
            width: 164px;
            background: #F3F5F7;
        }

        @media (max-width: $phone) {
            height: 104px;
            width: 101px;
        }
    }

    .main-content-block {
        background: $white;
        border-radius: 5px;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.10);
        min-height: 574px;

        @media (max-width: $desktop) {
            box-shadow: none;
        }
    }

    > .container {
        padding: 120px 12px 0;

        @media (max-width: $desktop) {
            padding: $largePad $regularPad 0;
        }

        @media (max-width: $phone) {
            padding: $bigPad $regularPad 0;
        }
    }

    @media (min-width: $extralargescreen) {
        .container,
        .container-lg,
        .container-md,
        .container-sm,
        .container-xl,
        .container-xxl {
            max-width: 1260px;
        }
    }
    
    @media (min-width: $widescreen) and (max-width: $extralargescreen) {
        .container,
        .container-lg,
        .container-md,
        .container-sm,
        .container-xl,
        .container-xxl {
            max-width: 1260px;
        }
    }
    
    @media (min-width: $tablet) and (max-width: $desktop) {
        .container {
            max-width: 566px;
        }
    }
    
    @media (max-width: $desktop) {
        .container {
            max-width: unset;
        }
    }
}
